<template>
  <section class="px-5 py-5" id="inventory">
    <v-row>
      <v-card
        color="purple darken-4"
        dark
        width="100%"
        class="m-4 p-5"
        height="150px"
      >
        <v-card-title class="font-weight-bold display-2 ml-2 mt-3">
          <span class="purple--text lighten-3 mx-2 text-capitalize">{{
            $route.params.category
          }}</span>
          Products
        </v-card-title>
      </v-card>
    </v-row>
    <v-row class="font-weight-medium mb-5 display-2">
      <v-spacer></v-spacer>
      <v-col class="d-flex" cols="12" lg="4">
        <v-select
          color="purple"
          v-model="brand"
          chips
          deletable-chips
          :items="products"
          @change="sortBrands"
          item-text="title"
          outlined
          label="Brand"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" md="6" v-for="(item, index) in products" :key="index">
        <product-card :item="item" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";

export default {
  name: "Inventory",
  data: () => ({
    brand: "",
    products: [],
    default: [],
  }),
  props: {
    category: {
      type: String,
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.setData(to.params.category));
  },
  beforeRouteUpdate(to, from, next) {
    this.products = null;
    this.setData(to.params.category);
    next();
  },
  methods: {
    sortBrands() {
      if(this.brand == null) {
        this.products = this.default
      } else {
        this.products = this.products.filter((x) => {
          return x.title == this.brand;
        });
      }
    },
    setData(tmp) {
      this.products = this.$store.getters[tmp];
      this.default = this.products.map((x) => x)
    },
  },
  components: {
    "product-card": ProductCard,
  },
};
</script>